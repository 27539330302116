// _blog.scss

.blog-post {
    background-color: #f9f9f9;
    // border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
  
  .blog-post-title {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
  }
  
  .blog-post-summary {
    font-size: 16px;
    color: #666;
    margin-bottom: 15px;
  }
  
  .learn-more-link {
    display: inline-block;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .learn-more-link:hover {
    background-color: #0056b3;
  }
  