///
/// Future Imperfect by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Sidebar */

	#sidebar {
		margin-right: _size(section-spacing);
		min-width: 22em;
		width: 22em;

		> * {
			border-top: solid 1px _palette(border);
			margin: _size(section-spacing) 0 0 0;
			padding: _size(section-spacing) 0 0 0;
		}

		> :first-child {
			border-top: 0;
			margin-top: 0;
			padding-top: 0;
		}

		@include breakpoint(large) {
			border-top: solid 1px _palette(border);
			margin: _size(section-spacing) 0 0 0;
			min-width: 0;
			padding: _size(section-spacing) 0 0 0;
			width: 100%;
		}

		#intro {
			display: flex;
			flex-direction: column;
			align-items: center;
			// justify-content: center;
			text-align: center; // This ensures that the text is also centered
			// height: 100%; // Ensure the section takes the full height of its container
		  
			.logo {
			  margin-bottom: 1em; // Add some space between the logo and the header
			}
		  
			header {
			  h2 {
				margin: 0.5em 0;
			  }
			  
			  p {
				margin: 0;
			  }
			}
		  }
	
	}